






































import { Component } from "vue-property-decorator";
import { UserModel, VolunteerModel } from "@/core/webapi";
import { ApiService } from "@/core/services";
import { GridPagerCpt } from "@/core/components/common/grid";
import AppVue from "@/AppVue.vue";
import { NotificationProvider } from "@/core/services/common/notification.provider";
import { AuditRecordTableCpt } from "@/modules/audit/components";
import {
  VolunteerSearchFilterCpt,
  VolunteerSearchTableCpt,
  VolunteerDetailCpt,
  VolunteerUpdateStatusCpt,
} from "./components";

@Component({
  components: {
    VolunteerSearchFilterCpt,
    VolunteerSearchTableCpt,
    GridPagerCpt,
    VolunteerDetailCpt,
    VolunteerUpdateStatusCpt,
    AuditRecordTableCpt,
  },
})
export default class VolunteerDetailView extends AppVue {
  model = new VolunteerModel({
    id: this.volunteerId,
    user: new UserModel({
      firstName: "",
      lastName: "",
    }),
    updatedByUser: new UserModel({
      firstName: "",
      lastName: "",
    }),
    applicationStatus: {},
  });

  get volunteerId() {
    return Number(this.$route.params.volunteerId);
  }

  async created() {
    await this.loadVolunteer();
  }

  async loadVolunteer() {
    NotificationProvider.loadingStart("Loading volunteer");
    try {
      const result = await ApiService.application().getVolunteerById(this.volunteerId);
      this.model = result.data;
    } finally {
      NotificationProvider.loadingStop();
    }
  }
}
