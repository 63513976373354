



























import { Component, Prop } from "vue-property-decorator";
import { ButtonCpt } from "@/core/components/common";
import { ConfirmCpt } from "@/core/components";
import AppVue from "@/AppVue.vue";
import { SimpleLookupModel, VolunteerModel } from "@/core/webapi";
import { ApiService, NotificationProvider } from "@/core/services";
import { ApplicationStatus } from "@/core/constants";
import moment from "moment";

@Component({
  components: {
    ButtonCpt,
    ConfirmCpt,
  },
})
export default class VolunteerUpdateStatusCpt extends AppVue {
  @Prop() volunteerId: number;
  @Prop() currentStatus: number;
  @Prop() model: VolunteerModel;

  showConfirm = false;
  selectedStatus?: SimpleLookupModel = null;
  statuses: SimpleLookupModel[] = [];

  get confirmMessage() {
    if (!this.selectedStatus || !this.selectedStatus.text) {
      return "";
    }
    const message = `Are you sure you want to update this volunteer status to ${this.selectedStatus.text}?`;
    if (
      this.selectedStatus.id === ApplicationStatus.Withdrawn ||
      this.selectedStatus.id === ApplicationStatus.Declined
    ) {
      return `<p>${message}</p>
              <p><strong>Please make sure to let this volunteer know 
              his/her application has been ${this.selectedStatus.text}.</strong></p>`;
    }
    if (this.selectedStatus.id === ApplicationStatus.AwaitingExperientialTraining) {
      if (this.model.isReturning) {
        return `<p>${message}</p>
              <p><strong>Please notice: this is a returning mentor who
                 has probably been trained previously.</strong></p>`;
      }

      if (this.model.lastTrainingDate && moment(this.model.lastTrainingDate).local() <= moment().local()) {
        return `<p>${message}</p>
              <p><strong>Please notice: This mentor is registered for 
                a training session that has already happened.</strong></p>`;
      }
    }

    return message;
  }

  async created() {
    await this.loadStatuses();
  }

  async loadStatuses() {
    this.isLoading = true;
    try {
      const result = await ApiService.application().getApplicationStatus();
      this.statuses = result.data;
    } finally {
      this.isLoading = false;
    }
  }

  isDisabled(id: number) {
    return id === this.currentStatus;
  }

  async setStatus(isConfirmed: boolean) {
    this.showConfirm = false;
    if (!isConfirmed || !this.selectedStatus || !this.selectedStatus.id) {
      this.selectedStatus = null;
      return;
    }

    this.isLoading = true;
    try {
      await ApiService.application().updateStatus(this.volunteerId, this.selectedStatus.id);
      this.$emit("status-updated");
      this.$eventHub.$emit("status-updated");
      NotificationProvider.success("Status successfully updated");
    } finally {
      this.selectedStatus = null;
      this.isLoading = false;
    }
  }

  confirmStatusUpdate(status: SimpleLookupModel) {
    this.selectedStatus = status;
    this.showConfirm = true;
  }

  getColor(id: number) {
    switch (id) {
      case ApplicationStatus.Declined:
        return "error";
      case ApplicationStatus.Withdrawn:
        return "secondary";
      default:
        return "primary";
    }
  }
}
