


























import { Component, Prop } from "vue-property-decorator";
import { InputTextareaCpt } from "@/core/components/common";

import AppVue from "@/AppVue.vue";
import { ApplicationModel, ApplicationLookups } from "@/core/webapi";

@Component({
  components: {
    InputTextareaCpt,
  },
})
export default class Step3Cpt extends AppVue {
  @Prop() model: ApplicationModel;
  @Prop() lookups: ApplicationLookups;

  get courseErrors() {
    return this.getError("courseId");
  }

  get yearOfStudyErrors() {
    return this.getError("yearOfStudyId");
  }

  private getError(propName: string) {
    const errors = ((this.model?.modelState as any)[propName] as any[]) || [];
    return errors[0] ?? "";
  }
}
