







import { Component } from "vue-property-decorator";

import AppVue from "@/AppVue.vue";

@Component
export default class Step6Cpt extends AppVue {}
