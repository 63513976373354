




























































import { Component, Prop } from "vue-property-decorator";
import { InputTextareaCpt, InputCheckboxCpt } from "@/core/components/common";

import AppVue from "@/AppVue.vue";
import { ApplicationModel, ApplicationLookups } from "@/core/webapi";

@Component({
  components: {
    InputTextareaCpt,
    InputCheckboxCpt,
  },
})
export default class Step5aCpt extends AppVue {
  @Prop() model: ApplicationModel;
  @Prop() lookups: ApplicationLookups;

  get sourceErrors() {
    return this.getError("sourceId");
  }

  get trainingSessionErrors() {
    return this.getError("trainingSessionId");
  }

  get hoodieSizeErrors() {
    return this.getError("hoodieSizeId");
  }

  private getError(propName: string) {
    const errors = ((this.model?.modelState as any)[propName] as any[]) || [];
    return errors[0] ?? "";
  }
}
