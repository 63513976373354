






























import { Component } from "vue-property-decorator";
import { ButtonCpt } from "@/core/components/common";

import AppVue from "@/AppVue.vue";

@Component({
  components: {
    ButtonCpt,
  },
})
export default class ApplicationCompleteView extends AppVue {
  created() {
    if (this.isStaffOrAdmin) {
      this.$router.push("/");
    }
  }
}
