





















































































import { Component, Prop } from "vue-property-decorator";
import { ApplicationStatusChipCpt } from "@/core/components";
import { VolunteerModel } from "@/core/webapi";
import AppVue from "@/AppVue.vue";

@Component({
  components: {
    ApplicationStatusChipCpt,
  },
})
export default class VolunteerSearchTableCpt extends AppVue {
  @Prop() items: VolunteerModel[];
}
