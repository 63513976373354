





















































































































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import _ from "lodash";

import {
  ButtonCpt,
  InputAutocompleteCpt,
  InputRadioCpt,
  InputNumberCpt,
  InputFieldCpt,
  AcademicYearSelectCpt,
} from "@/core/components/common";
import { VolunteerModel, VolunteerSearchFilterModel, VolunteerSearchLookups, SimpleLookupModel } from "@/core/webapi";
import AppVue from "@/AppVue.vue";
import { GridModel } from "@/core/models";
import { ApiService } from "@/core/services";

@Component({
  components: {
    InputAutocompleteCpt,
    ButtonCpt,
    InputFieldCpt,
    InputRadioCpt,
    InputNumberCpt,
    AcademicYearSelectCpt,
  },
})
export default class VolunteerSearchFilterCpt extends AppVue {
  @Prop({ default: null }) filter: VolunteerSearchFilterModel;
  @Prop() lookups: VolunteerSearchLookups;
  @Prop() loading: boolean;
  @Prop() grid: GridModel<VolunteerModel>;

  internalFilter = new VolunteerSearchFilterModel();
  trainingSessions: SimpleLookupModel[] = [];
  tags: SimpleLookupModel[] = [];
  isLoadingTrainingSessions = false;

  @Watch("filter", { deep: true })
  onFilterChanged() {
    this.internalFilter = this.filter;
  }

  async created() {
    this.onFilterChanged();
    await this.loadTrainingSession();
  }

  async loadTrainingSession() {
    if (!this.currentAcademicYear) {
      return;
    }

    this.isLoadingTrainingSessions = true;
    try {
      const response = await ApiService.training().getByAcademicYear(this.currentAcademicYear);
      this.trainingSessions = response.data;

      this.trainingSessions.unshift({
        id: null,
        text: "All",
      } as SimpleLookupModel);
    } finally {
      this.isLoadingTrainingSessions = false;
    }
  }

  refresh() {
    this.$emit("refresh");
  }

  onChange() {
    this.$emit("filter-changed", this.internalFilter);
  }

  async onChangeAcademicYear() {
    await this.loadTrainingSession();

    if (this.trainingSessions.filter(item => item.id === this.internalFilter.trainingSessionId).length <= 0) {
      this.internalFilter.trainingSessionId = null;
    }
    this.onChange();
  }

  onTagsChange(selectedTags: (SimpleLookupModel | string)[]) {
    this.tags = _.filter(selectedTags, tag => !_.isString(tag)) as SimpleLookupModel[];

    if (this.tags?.length) {
      this.internalFilter.tagIds = _.map(this.tags, tag => tag.id);
    } else {
      this.internalFilter.tagIds = null;
    }

    this.onChange();
  }
}
