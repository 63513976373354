


































































































































































































import { Component, Prop, Watch } from "vue-property-decorator";
import _ from "lodash";

import { SimpleLookupModel, VolunteerEditLookups, VolunteerModel } from "@/core/webapi";
import {
  BaseFormComponent,
  ButtonCpt,
  InputFieldCpt,
  InputNumberCpt,
  InputAutocompleteCpt,
  InputYesNoRadioCpt,
} from "@/core/components/common/form";
import { ApiService, NotificationProvider } from "@/core/services";

@Component({
  components: {
    ButtonCpt,
    InputFieldCpt,
    InputNumberCpt,
    InputAutocompleteCpt,
    InputYesNoRadioCpt,
  },
})
export default class VolunteerEditFormCpt extends BaseFormComponent {
  @Prop() model: VolunteerModel;

  @Watch("model", { deep: true })
  async onModelChanged() {
    await this.loadLookups();
  }

  lookups = new VolunteerEditLookups({
    academicYears: [],
    courses: [],
    yearsOfStudy: [],
    hoodieSizes: [],
    trainingSessions: [],
    tags: [],
  });

  async onSubmitForm(): Promise<void> {
    // SEE: https://github.com/vuetifyjs/vuetify/issues/3424#issuecomment-370411104
    await this.$nextTick();

    this.isLoading = true;
    try {
      await ApiService.application().updateVolunteer(this.model);
      NotificationProvider.success("Volunteer successfully updated");
      this.$router.push(`/volunteer-details/${this.model.id}`);
    } catch (err) {
      if (err.response && err.response.data) {
        this.model.modelState = err.response.data.modelState;
      }
    } finally {
      this.isLoading = false;
    }
  }

  async created() {
    await this.loadLookups();
  }

  async loadLookups() {
    this.isLoading = true;
    try {
      const response = await ApiService.application().getVolunteerEditLookups(this.model.id);
      this.lookups = response.data;
      this.lookups.hoodieSizes.unshift(
        new SimpleLookupModel({
          id: 0,
          text: "-Not-Required-",
        }),
      );
    } finally {
      this.isLoading = false;
    }
  }

  // This getter had to be added because the getVolunteerById() from the parent view and loadLookups()
  // from this component go into a race condition and the UI doesn't update properly otherwise.
  get trainingSessions() {
    if (!this.lookups?.trainingSessions) {
      return [];
    }

    const trainingSessions = _.cloneDeep(this.lookups.trainingSessions);
    if (this.model?.trainingSession) {
      // Avoid adding a duplicate to the list
      const currentSession = _.find(trainingSessions, session => session.id === this.model.trainingSession.id);
      if (!currentSession) {
        trainingSessions.unshift(this.model.trainingSession);
      }
    }
    return trainingSessions;
  }

  getTagText(tag: SimpleLookupModel) {
    return tag.text;
  }

  onTagsChange(selectedTags: (SimpleLookupModel | string)[]) {
    this.model.tags = _.map(selectedTags, tag => (_.isString(tag) ? { text: tag } : tag));
  }
}
