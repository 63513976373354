
































































import { Component, Prop } from "vue-property-decorator";
import { ValidationMessageCpt, InputCheckboxCpt } from "@/core/components/common";

import AppVue from "@/AppVue.vue";
import { ApplicationModel } from "@/core/webapi";

@Component({
  components: {
    ValidationMessageCpt,
    InputCheckboxCpt,
  },
})
export default class Step1Cpt extends AppVue {
  @Prop() model: ApplicationModel;
}
