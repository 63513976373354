



































import { Component, Prop } from "vue-property-decorator";

import AppVue from "@/AppVue.vue";
import { ApplicationModel } from "@/core/webapi";

@Component
export default class Step4Cpt extends AppVue {
  @Prop() model: ApplicationModel;
}
