























import { Component } from "vue-property-decorator";
import { UserModel, VolunteerModel } from "@/core/webapi";
import { ApiService } from "@/core/services";
import AppVue from "@/AppVue.vue";
import { NotificationProvider } from "@/core/services/common/notification.provider";
import { VolunteerEditFormCpt } from "./components";

@Component({
  components: {
    VolunteerEditFormCpt,
  },
})
export default class VolunteerEditView extends AppVue {
  model = new VolunteerModel({
    id: this.volunteerId,
    user: new UserModel({
      firstName: "",
      lastName: "",
    }),
    updatedByUser: new UserModel({
      firstName: "",
      lastName: "",
    }),
    applicationStatus: {},
    course: {},
    yearOfStudy: {},
    academicYear: {},
    hoodieSize: {},
    modelState: {},
    trainingSession: {},
  });

  get volunteerId() {
    return Number(this.$route.params.volunteerId);
  }

  async created() {
    await this.loadVolunteer();
  }

  async loadVolunteer() {
    NotificationProvider.loadingStart("Loading volunteer");
    try {
      const result = await ApiService.application().getVolunteerById(this.volunteerId);
      this.model = result.data;
      this.$forceUpdate();
    } finally {
      NotificationProvider.loadingStop();
    }
  }
}
