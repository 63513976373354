












































































import { Component } from "vue-property-decorator";
import { SimpleLookupModel, VolunteerModel, VolunteerSearchFilterModel, VolunteerSearchLookups } from "@/core/webapi";
import BaseGridComponent from "@/core/components/common/grid/BaseGridComponent.vue";
import { ApiService, GridService, NotificationProvider } from "@/core/services";
import { GridPagerCpt } from "@/core/components/common/grid";
import { ImportTrainingResultDialogCpt } from "@/modules/training/components";
import { VolunteerSearchFilterCpt, VolunteerSearchTableCpt } from "./components";

@Component({
  components: {
    VolunteerSearchFilterCpt,
    VolunteerSearchTableCpt,
    GridPagerCpt,
    ImportTrainingResultDialogCpt,
  },
})
export default class VolunteerSearchView extends BaseGridComponent<VolunteerModel> {
  lookups = new VolunteerSearchLookups();

  downloadingCsv = false;
  showImport = false;

  async created() {
    if (!this.$route.query.pageSize) {
      this.$route.query.pageSize = "30";
    }
    this.grid = GridService.gridFactory<VolunteerModel>(this.getNewFilter());
    await this.loadLookups();
    this.load();
  }

  importClosed(result: boolean) {
    this.showImport = false;
    if (result) {
      this.load();
    }
  }

  filterChanged(filter: VolunteerSearchFilterModel) {
    this.grid.filter = filter;
  }

  async loadLookups() {
    this.isLoading = true;
    try {
      const response = await ApiService.application().getVolunteerSearchLookups();
      this.lookups = response.data;
      this.lookups.applicationStatuses.unshift({
        id: null,
        text: "All",
      } as SimpleLookupModel);
    } finally {
      this.isLoading = false;
    }
  }

  async refresh() {
    this.grid.filter.page = 1;
    this.load();
  }

  getNewFilter() {
    const query = this.$route.query as any;

    // If academic year in URL, it must update the localStorage
    const academicYear = Number(query.academicYearId);
    if (academicYear && academicYear !== this.currentAcademicYear) {
      this.currentAcademicYear = academicYear;
    }

    return new VolunteerSearchFilterModel({
      pageSize: Number(query.pageSize),
      page: Number(query.page) || 1,
      academicYearId: this.currentAcademicYear,
      applicationStatusId: Number(query.applicationStatusId) || null,
      trainingSessionId: Number(query.trainingSessionId) || null,
      missedSessions: Number(query.missedSessions) || null,
      search: query.search || "",
      sortBy: query.sortBy || "CreatedAt",
      sortOrder: query.sortOrder || "desc",
      tagIds: query.tagIds || null,
      courseId: Number(query.courseId) || null,
      // SEE: https://github.com/vuetifyjs/vuetify/issues/9073
      // Since 0 is an invalid nullable boolean on WebApi, it will be ignored and deserialized to null
      hasBuddy: Boolean(query.hasBuddy) || null,
      isMature: Boolean(query.isMature) || null,
      isInternational: Boolean(query.isInternational) || null,
      isAccessTapHear: Boolean(query.isAccessTapHear) || null,
      isReturning: Boolean(query.isReturning) || null,
    });
  }

  private load() {
    this.initialize(
      // eslint-disable-next-line
      filter => ApiService.application().filterVolunteers(filter),
      "volunteer-search",
      this.grid?.filter || this.getNewFilter(),
    );
  }

  copyEmailsToClipboard() {
    const emails = this.grid.collection.items.map(p => p.user.email);
    navigator.clipboard.writeText(emails.join(";"));
    NotificationProvider.success("Emails copied to clipboard!");
  }

  async downloadCsv() {
    this.downloadingCsv = true;
    try {
      const response = await ApiService.application().getVolunteersCsv(this.grid.filter);
      const heading = "data:text/csv;charset=utf-8,";
      const sanitized = response.data.replace(/(^\[)|(\]$)/gm, "");
      const encoded = encodeURI(`${heading}${sanitized}`);

      const link = document.createElement("a");
      link.setAttribute("href", encoded);
      link.setAttribute("download", "Volunteers.csv");
      link.click();
    } finally {
      this.downloadingCsv = false;
    }
  }
}
