









































































































































































import { Component, Prop } from "vue-property-decorator";
import { ApplicationStatusChipCpt, YesNoChipCpt, ButtonCpt } from "@/core/components";
import { UserModel, VolunteerModel } from "@/core/webapi";
import AppVue from "@/AppVue.vue";

@Component({
  components: {
    ApplicationStatusChipCpt,
    YesNoChipCpt,
    ButtonCpt,
  },
})
export default class VolunteerDetailCpt extends AppVue {
  @Prop() model: VolunteerModel;

  get mentorGroupUrl() {
    if (!this.model || !this.model.mentorGroup) {
      return "";
    }

    // eslint-disable-next-line
    return `/mentor-groups?yearId=${this.model.mentorGroup.yearId}&courseId=${this.model.mentorGroup.courseId}&mentors=${this.model.mentorGroup.mentors}`;
  }

  getFullName(user: UserModel) {
    return `${user.firstName} ${user.lastName}`;
  }
}
