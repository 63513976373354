










































import { Component } from "vue-property-decorator";
// import _ from "lodash";

import AppVue from "@/AppVue.vue";
import { ApplicationLookups, ApplicationModel } from "@/core/webapi";
import { AccountService, ApiService, NotificationProvider } from "@/core/services";
import { ValidationHintCpt } from "@/core/components/common";
import { Step1Cpt, Step2Cpt, Step3Cpt, Step4Cpt, Step5aCpt, Step5bCpt, Step6Cpt } from "./components";

@Component({
  components: {
    ValidationHintCpt,
    Step1Cpt,
    Step2Cpt,
    Step3Cpt,
    Step4Cpt,
    Step5aCpt,
    Step5bCpt,
    Step6Cpt,
  },
})
export default class ApplicationView extends AppVue {
  internalLoading = false;

  lookups = new ApplicationLookups();
  // NOTE: Keeping this for easier testing / debugging in the future
  // model = new ApplicationModel({
  //   // Temporary model for quicker testing
  //   step: 6,
  //   // Step 1
  //   acceptedTnC: true,
  //   availableForOrientation: true,
  //   canCommitTo3h: true,
  //   willCompleteRefresherTraining: true,
  //   isOver18: true,
  //   // Step 2
  //   firstName: "ftkit",
  //   lastName: "volunteer",
  //   phoneNumber: "08312345678",
  //   // Step 3
  //   courseId: 2,
  //   yearOfStudyId: 3,
  //   // Step 4
  //   isMature: true,
  //   isInternational: true,
  //   isAccessTapHear: true,
  //   isReturning: false,
  //   // Step 5a
  //   // canCommitTo3h // Already added
  //   reasonForApplication: "asdf",
  //   mostImportantNewStudentInfo: "sdfg",
  //   responseInfoWaiver: true,
  //   sourceId: 1,
  //   trainingSessionId: 1,
  //   hoodieSizeId: 1,
  //   // Step 5b
  //   changesToMentorProgramme: "",
  //   additionalTrainingRequest: "",
  //   modelState: {},
  // });

  // Empty default model
  model = new ApplicationModel({
    step: 1,
    // Step 1
    acceptedTnC: false,
    availableForOrientation: false,
    canCommitTo3h: false,
    willCompleteRefresherTraining: false,
    isOver18: false,
    // Step 2
    firstName: "",
    lastName: "",
    phoneNumber: "",
    // Step 3
    courseId: 0,
    yearOfStudyId: 0,
    // Step 4
    // defaults are not needed because the form needs to show up without responses
    // isMature: true,
    // isInternational: true,
    // isAccessTapHear: true,
    // isReturning: false,
    // Step 5a
    // canCommitTo3h // Already added
    reasonForApplication: "",
    mostImportantNewStudentInfo: "",
    responseInfoWaiver: true,
    sourceId: 0,
    trainingSessionId: 0,
    hoodieSizeId: 0,
    // Step 5b
    changesToMentorProgramme: "",
    additionalTrainingRequest: "",
    modelState: {},
  });

  async created() {
    if (this.isStaffOrAdmin) {
      this.$router.push("/");
      return;
    }

    const response = await ApiService.application().getApplicationLookups();
    this.lookups = response.data;
    this.model.firstName = this.authToken.firstName;
    this.model.lastName = this.authToken.lastName;
  }

  get isFirstStep() {
    return this.model.step <= 1;
  }

  get isLastStep() {
    return this.model?.step >= 6;
  }

  get disableBackButton() {
    return this.isFirstStep || this.internalLoading;
  }

  get disableNextButton() {
    return this.internalLoading;
  }

  async goBack() {
    const prevStep = this.model.step - 1;

    await this.goToStep(prevStep);
  }

  async goNext() {
    if (this.isLastStep) {
      // Finish the application if goNext() was called on the last step
      if (await this.submitModel()) {
        NotificationProvider.success("Application successful!");
        await AccountService.refreshToken();
        this.$forceUpdate();
        this.$router.push("application-complete");
        this.$eventHub.$emit("APPLICATION-COMPLETE");
        return;
      }
    }

    const nextStep = this.model.step + 1;

    if (await this.submitModel()) {
      await this.goToStep(nextStep);
    }
  }

  private async submitModel() {
    // this.internalLoading = true;

    this.model.modelState = {};
    const isSuccess = await ApiService.application()
      .postApplication(this.model)
      .then(
        () => true,
        error => {
          if (error.response && error.response.data) {
            this.model.modelState = error.response.data.modelState;
          }
          this.$forceUpdate();
          return false;
        },
      );
    this.$forceUpdate();
    // this.internalLoading = false;
    return isSuccess;
  }

  private async goToStep(step: number) {
    this.model.step = step;
  }
}
